<script setup lang="ts">
import { ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import {
  Dialog,
  DialogPanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'
import {
  Bars3Icon,
  Cog6ToothIcon,
  HomeIcon,
  XMarkIcon,
  UserGroupIcon,
  PaperAirplaneIcon,
  MegaphoneIcon,
  RectangleGroupIcon,
  WrenchScrewdriverIcon,
  RocketLaunchIcon,
  KeyIcon,
} from '@heroicons/vue/24/outline'
import {ChevronDownIcon} from '@heroicons/vue/20/solid'

const { t } = useI18n();
const navigation = ref([
  { name: t('layouts.navigation-dashboard'), href: '/admin/dashboard', icon: HomeIcon, current: false },
  { name: t('layouts.navigation-users'), href: '/admin/users', icon: UserGroupIcon, current: false },
  { name: t('layouts.navigation-articles'), href: '/admin/news', icon: MegaphoneIcon, current: false },
])
const teams = ref([

])

const sidebarOpen = ref(false)
const { data, signOut } = useAuth();
const route = useRoute()
const toast = useToast();

const updateCurrentPage = (currentUrl: string) => {
  const cleanUrl = currentUrl.replace(/^\/(en-US|it-IT)/, '');
  navigation.value.forEach(item => {
    item.current = item.href === cleanUrl;
  });
  teams.value.forEach(item => {
    item.current = item.href === cleanUrl;
  });
}
watch(() => route.path, (newPath) => {
  updateCurrentPage(newPath);
}, { immediate: true });

async function handleLogout() {
  toast.add({
    id: 'sign-out',
    icon: 'i-heroicons-check-circle',
    color: 'green',
    title: t('layouts.sign-out-success'),
    description: t('layouts.redirecting'),
  })
  await signOut({ callbackUrl: '/' });
}
</script>

<template>
  <div>
    <TransitionRoot as="template" :show="sidebarOpen">
      <Dialog as="div" class="relative z-50 lg:hidden" @close="sidebarOpen = false">
        <TransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="transition-opacity ease-linear duration-300" leave-from="opacity-100" leave-to="opacity-0">
          <div class="fixed inset-0 bg-gray-900/80" />
        </TransitionChild>

        <div class="fixed inset-0 flex">
          <TransitionChild as="template" enter="transition ease-in-out duration-300 transform" enter-from="-translate-x-full" enter-to="translate-x-0" leave="transition ease-in-out duration-300 transform" leave-from="translate-x-0" leave-to="-translate-x-full">
            <DialogPanel class="relative mr-16 flex w-full max-w-xs flex-1">
              <TransitionChild as="template" enter="ease-in-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-300" leave-from="opacity-100" leave-to="opacity-0">
                <div class="absolute left-full top-0 flex w-16 justify-center pt-5">
                  <button type="button" class="-m-2.5 p-2.5" @click="sidebarOpen = false">
                    <span class="sr-only">{{ $t('layouts.close-sidebar') }}</span>
                    <XMarkIcon class="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </TransitionChild>
              <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-red-800 px-6 pb-4">
                <div class="flex h-16 shrink-0 items-center">

                </div>
                <nav class="flex flex-1 flex-col">
                  <ul role="list" class="flex flex-1 flex-col gap-y-7">
                    <li>
                      <ul role="list" class="-mx-2 space-y-1">
                        <li v-for="item in navigation" :key="item.name">
                          <a :href="item.href" :class="[item.current ? 'bg-red-900 text-white' : 'text-gray-200 hover:text-white hover:bg-red-900', 'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold']">
                            <component :is="item.icon" :class="[item.current ? 'text-white' : 'text-gray-200 group-hover:text-white', 'h-6 w-6 shrink-0']" aria-hidden="true" />
                            {{ item.name }}
                          </a>
                        </li>
                      </ul>
                    </li>
<!--                    <li>
                      <div class="text-xs font-semibold leading-6 text-indigo-200">{{ $t('layouts.manage-yachts') }}</div>
                      <ul role="list" class="-mx-2 mt-2 space-y-1">
                        <li v-for="team in teams" :key="team.name">
                          <a :href="team.href" :class="[team.current ? 'bg-red-900 text-white' : 'text-indigo-200 hover:text-white hover:bg-red-900', 'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold']">
                            <component :is="team.icon" :class="[team.current ? 'text-white' : 'text-indigo-200 group-hover:text-white', 'h-6 w-6 shrink-0']" aria-hidden="true" />
                            <span class="truncate">{{ team.name }}</span>
                          </a>
                        </li>
                      </ul>
                    </li>-->
                    <li class="mt-auto">
                      <a href="#" class="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-indigo-200 hover:bg-red-900 hover:text-white">
                        <Cog6ToothIcon class="h-6 w-6 shrink-0 text-indigo-200 group-hover:text-white" aria-hidden="true" />
                        {{ $t('layouts.settings') }}
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>

    <div class="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
      <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-red-800 px-6 pb-4">
        <div class="flex h-16 shrink-0 items-center">

        </div>
        <nav class="flex flex-1 flex-col">
          <ul role="list" class="flex flex-1 flex-col gap-y-7">
            <li>
              <ul role="list" class="-mx-2 space-y-1">
                <li v-for="item in navigation" :key="item.name">
                  <NuxtLink :to="localePath(item.href)" :class="[item.current ? 'bg-red-900 text-white' : 'text-gray-200 hover:text-white hover:bg-red-900', 'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold']">
                    <component :is="item.icon" :class="[item.current ? 'text-white' : 'text-gray-200 group-hover:text-white', 'h-6 w-6 shrink-0']" aria-hidden="true" />
                    {{ item.name }}
                  </NuxtLink>
                </li>
              </ul>
            </li>
<!--            <li>
              <div class="text-xs font-semibold leading-6 text-indigo-200">{{ $t('layouts.manage-yachts') }}</div>
              <ul role="list" class="-mx-2 mt-2 space-y-1">
                <li v-for="team in teams" :key="team.name">
                  <NuxtLink :to="localePath(team.href)" :class="[team.current ? 'bg-red-900 text-white' : 'text-indigo-200 hover:text-white hover:bg-red-900', 'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold']">
                    <component :is="team.icon" :class="[team.current ? 'text-white' : 'text-indigo-200 group-hover:text-white', 'h-6 w-6 shrink-0']" aria-hidden="true" />
                    <span class="truncate">{{ team.name }}</span>
                  </NuxtLink>
                </li>
              </ul>
            </li>-->
            <li class="mt-auto">
              <NuxtLink href="#" class="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-200 hover:bg-red-900 hover:text-white">
                <Cog6ToothIcon class="h-6 w-6 shrink-0 text-gray-200 group-hover:text-white" aria-hidden="true" />
                {{ $t('layouts.settings') }}
              </NuxtLink>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <div class="lg:pl-72">
      <div class="sticky top-0 z-40 flex h-20 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
        <button type="button" class="-m-2.5 p-2.5 text-gray-700 lg:hidden" @click="sidebarOpen = true">
          <span class="sr-only">{{ $t('layouts.open-sidebar') }}</span>
          <Bars3Icon class="h-6 w-6" aria-hidden="true" />
        </button>

        <div class="h-6 w-px bg-gray-900/10 lg:hidden" aria-hidden="true" />

        <div class="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
          <div class="relative flex flex-1 items-center">
            <NuxtLink :to="localePath('/')" class="mx-auto"><NuxtImg class="h-16" src="/assets/logo.webp" alt="Logo" /></NuxtLink>
          </div>
          <div class="flex items-center gap-x-4 lg:gap-x-6">
            <button type="button" class="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500">
              <span class="sr-only">{{ $t('layouts.view-notifications') }}</span>
              <LangSwitcher />
            </button>

            <div class="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10" aria-hidden="true" />

            <Menu as="div" class="relative">
              <MenuButton class="-m-1.5 flex items-center p-1.5">
                <span class="sr-only">{{ $t('layouts.open-user-menu') }}</span>
                <span class="hidden lg:flex lg:items-center">
                  <span class="ml-4 text-sm font-semibold leading-6 text-gray-900" aria-hidden="true">{{ (data?.user as any)?.username }}</span>
                  <ChevronDownIcon class="ml-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                </span>
              </MenuButton>
              <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                <MenuItems class="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                  <MenuItem v-slot="{ active }">
                    <a :class="[active ? 'bg-gray-50' : '', 'block px-3 py-1 text-sm leading-6 text-gray-900']" href="/">
                      {{ $t('layouts.home') }}
                    </a>
                  </MenuItem>
                  <MenuItem v-slot="{ active }">
                    <a :class="[active ? 'bg-gray-50' : '', 'block px-3 py-1 text-sm leading-6 text-gray-900']" href="/" @click="handleLogout">
                      {{ $t('layouts.sign-out') }}
                    </a>
                  </MenuItem>
                </MenuItems>
              </transition>
            </Menu>
          </div>
        </div>
      </div>

      <main class="py-10">
        <div class="px-8 sm:px-12 lg:px-16">
          <slot/>
        </div>
      </main>
    </div>
  </div>
</template>

<style>
  img, button, input:focus-visible {
    outline: none;
  }
  html {
    background-color: #F2F2F2;
  }
  .page-enter-active,
  .page-leave-active {
    transition: none!important;
  }
  .page-enter-from,
  .page-leave-to {
    opacity: 0!important;
    filter: blur(0rem)!important;
  }
</style>

